import React, { useEffect, useState } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { ApolloConsumer } from 'react-apollo'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography, Button, Theme } from '@material-ui/core'
import { Formik, FormikProps, Form, Field, FormikConfig } from 'formik'
import { ApolloClient, DocumentNode } from 'apollo-boost'
import clsx from 'clsx'
import { localStorage } from 'react-storage'
import { CertificateOfAnalysisDocument } from '@src/queries/CertificateOfAnalysisQuery.generated'
import { CertificateOfOriginDocument } from '@src/queries/CertificateOfOriginQuery.generated'
import {
  SampleCertificateOfAnalysisQuery,
  useSampleCertificateOfAnalysisQuery,
} from '@src/queries/SampleCertificateOfAnalysisQuery.generated'
import { PdfDownloadDocument } from '@src/queries/CoaCertificatePDFQuery.generated'

import {
  certificateCOARoute,
  certificateCOORoute,
  support,
  useRouter,
} from '@src/routes'
import { CertificateType, Content } from '@src/types/graphql-types'
import { getLocalizedUrl } from '@utils/regional'
import { useUserSession } from '@utils/useUserSession'
import { UserSession } from '@utils/userSession'
import messages from '@utils/messages'
import { CertificateSearchType } from '@utils/searchUtils'
import {
  GlobalSnackbarState,
  SnackbarType,
  useGlobalSnackbar,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import LiquidCertificateAutoCompleteAdapter from '@src/components/LiquidCertificateAutoCompleteAdapter'
import CircleLoader from '@src/components/CircleLoader'
import vrStyles from '@src/styles/utils/vrStyles'
import {
  sendSearchCertificatesEvent,
  sendViewSampleCOAEvent,
  sendErrorEvent,
} from '@src/utils/analytics'
import CertficateNotFoundModal from '@src/components/CertificateNotFound/CertificateNotFound'
import { isNil } from 'lodash'
import LiquidSelectAdapter from '@src/components/LiquidSelectAdapter'
import { CertificateName, downloadPDF } from './certificateUtils'
import ViewSampleCoa, { SampleCoaLinkType } from './ViewSampleCoa'
import { LotNumberInstructionsDialog } from '@src/components/LotNumberInstructions'
import { Linq } from '@src/components/Linq'
import { isServer } from '@src/utils/isServer'
import NeedSampleCOA from './NeedSampleCOA'
import { AbbreviatedQmDocumentType } from '@src/utils/analytics/enums'

export const useStyles = makeStyles((theme: Theme) => {
  const { vr2, vr4, vr1, vr6 } = vrStyles

  return {
    sampleQueryLinkContainer: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        borderLeft: `solid 1px ${theme.palette.grey[400]}`,
      },
    },
    titleContainer: {
      display: 'block',
      ...vr1,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    title: {
      paddingBottom: theme.spacing(2),
    },
    submitButton: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    instructions: {
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    vr2,
    vr4,
    vr6,
    lotMessages: {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.spacing(6),
    },
    advancedSearch: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: theme.typography.pxToRem(16),
      gap: 4,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    expandedLayout: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(4, 4, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 6, 0),
      },
      [theme.breakpoints.down('md')]: {
        border: '0px',
        padding: theme.spacing(0, 0, 0),
      },
    },
    divider: {
      backgroundColor: theme.palette.grey[400],
      margin: theme.spacing(6, 0, 4),
    },
    bodyText: {
      marginBottom: theme.spacing(2),
    },
    formMargin: {
      marginBottom: theme.spacing(6),
    },
  }
})

export interface CertificateFormProps {
  type: CertificateType
  brandKey?: string
  lotNumber?: string
  onChangeLotNumber?: (value: string) => void
  productNumber?: string
  title: React.ReactNode
  instructions?: React.ReactNode
  productInput?: boolean
  dontShowSampleLink?: boolean
  showSkuInput?: boolean
  isBlueProduct?: boolean
  text?: Content
  options?: string[]
  isExpandedLayout?: boolean
  allFieldsRequired?: boolean
}

interface CertificateFormValues {
  lotNumber: string
  productNumber: string
  sku: string
}

export const handleCertificatePdf = async (
  client: ApolloClient<object>,
  props: CertificateFormProps,
  values: CertificateFormValues,
  intl: IntlShape,
  setGlobalSnackbarState: React.Dispatch<
    React.SetStateAction<GlobalSnackbarState>
  >,
  setPDFLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setCertificateNotFoundModal?: React.Dispatch<
    React.SetStateAction<CertificateType | null>
  >
) => {
  try {
    const { unit, owner } = props.text || {}
    const term = props?.productNumber || values?.productNumber
    setPDFLoading(true)
    const { errors, data } = await client.query({
      query: PdfDownloadDocument,

      variables: {
        input: {
          unit,
          owner,
          term,
          type: props.type,
          lotnumber: values?.lotNumber,
        },
      },
    })

    if (errors || !data || data.error) throw Error()

    downloadPDF(data.pdfDownload, term)
    setPDFLoading(false)
    sendSearchCertificatesEvent({
      section: props.type,
      productNumber: values?.productNumber,
      lotNumber: values?.lotNumber,
    })
  } catch (error) {
    sendErrorEvent(
      {
        description: `${props.type?.toLowerCase()} not found`,
        errorCategory: 'file download errors',
        productId: values?.sku || values?.productNumber,
        lotNumber: values?.lotNumber,
      },
      {
        eventCategory: 'downloads',
        eventAction: `${props.type?.toLowerCase()} search`,
        eventLabel: `Sorry, unable to find certificate > ${values?.productNumber?.trim()} > ${values?.lotNumber?.trim()}`,
        eventInteractionType: 0,
      }
    )
    setPDFLoading(false)
    if (
      (props.type === CertificateType.Coa ||
        props.type === CertificateType.Coo) &&
      setCertificateNotFoundModal
    ) {
      setCertificateNotFoundModal(props.type)
    } else {
      setGlobalSnackbarState({
        open: true,
        message: intl.formatMessage(
          {
            id: 'NO_PRODUCT_DOC_ONLINE',
            defaultMessage:
              'In some cases, our product documentation may not be available online. Please {contactUs} to request a copy.',
          },
          {
            contactUs: (
              <Linq href={support.customerSupport()}>
                <FormattedMessage
                  id="CONTACT_US_LOWERCASE"
                  defaultMessage="contact us"
                />
              </Linq>
            ),
          }
        ),
        variant: SnackbarType.Error,
      })
    }
  }
}

export const showCertificate = async (
  client: ApolloClient<object>,
  dataAccessor: string,
  intl: IntlShape,
  setGlobalSnackbarState: React.Dispatch<
    React.SetStateAction<GlobalSnackbarState>
  >,
  isCOA: boolean,
  queryData: SampleCertificateOfAnalysisQuery | undefined,
  queryOptions: QueryOptions | null,
  userSession: UserSession,
  props: CertificateFormProps,
  setCertificateNotFoundModal: React.Dispatch<
    React.SetStateAction<CertificateType | null>
  >,
  values?: CertificateFormValues
) => {
  try {
    const data = !!queryData
      ? queryData
      : queryOptions &&
        (await client.query(queryOptions).then(({ data }) => data))
    const certificate = data[dataAccessor]

    if (certificate.streamData) {
      const byteArray = new Uint8Array(certificate.streamData.length)
      for (let i = 0; i < certificate.streamData.length; i++) {
        byteArray[i] = certificate.streamData.charCodeAt(i) & 0xff
      }
      const blob = new Blob([byteArray], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    } else if (certificate.url) {
      dataAccessor !== 'getSampleCertificate' &&
        sendSearchCertificatesEvent({
          section: props.type,
          productNumber:
            queryOptions?.variables?.productNumber || props.productNumber || '',
          lotNumber: queryOptions?.variables?.lotNumber || '',
        })

      if (isCOA && dataAccessor === 'getSampleCertificate') {
        const gaFileName = certificate.url.split('/').pop()
        const productId =
          queryOptions?.variables?.productNumber ||
          props.productNumber ||
          undefined
        const lotNumber = queryOptions?.variables?.lotNumber || undefined
        fetch(certificate.url).then((response) => {
          if (response.status === 200) {
            sendViewSampleCOAEvent({
              fileExtension: 'pdf',
              fileName: gaFileName?.substring(0, gaFileName.length - 4) || null,
              productId: productId,
              linkUrl: certificate.url || null,
            })
          } else {
            sendErrorEvent(
              {
                description: `${props.type?.toLowerCase()} not found`,
                errorCategory: 'file download errors',
                productId: productId,
                lotNumber: lotNumber,
              },
              {
                eventCategory: 'downloads',
                eventAction: `${props.type?.toLowerCase()} search`,
                eventLabel: `Sorry, unable to find certificate > ${productId?.trim()} > ${lotNumber?.trim()}`,
                eventInteractionType: 0,
              }
            )
          }
        })
      }
      // This links directly to a certificate PDF via the certificatesProxy
      window.open(certificate.url, '_blank')
    } else if (certificate.testSpecifications) {
      // This builds a certificate in the certificate route with the string of JSON mapped to testSpecifications
      const certificateRoute = isCOA ? certificateCOARoute : certificateCOORoute
      const localizedUrl = getLocalizedUrl(
        userSession,
        certificateRoute.index(
          certificate?.product?.brand?.key ?? certificate?.brand ?? '',
          queryOptions?.variables?.productNumber || props.productNumber || '',
          certificate.lotNumber
        )
      )
      dataAccessor !== 'getSampleCertificate' &&
        sendSearchCertificatesEvent({
          section: props.type,
          productNumber:
            queryOptions?.variables?.productNumber || props.productNumber || '',
          lotNumber: queryOptions?.variables?.lotNumber || '',
          linkUrl: localizedUrl.as,
        })

      if (isCOA && dataAccessor === 'getSampleCertificate') {
        sendViewSampleCOAEvent({
          fileExtension: 'html',
          fileName:
            queryOptions?.variables?.lotNumber ||
            certificate?.lotNumber ||
            null,
          productId:
            queryOptions?.variables?.productNumber ||
            props.productNumber ||
            null,
          linkUrl: `${localizedUrl.as}` || null,
        })
      }
      window.open(localizedUrl.as, '_blank')
    } else {
      throw Error()
    }
  } catch (error) {
    dataAccessor !== 'getSampleCertificate' &&
      sendErrorEvent(
        {
          description: `${props.type?.toLowerCase()} not found`,
          errorCategory: 'file download errors',
          productId: values?.sku || values?.productNumber,
          lotNumber: values?.lotNumber,
        },
        {
          eventCategory: 'downloads',
          eventAction: `${props.type?.toLowerCase()} search`,
          eventLabel: `Sorry, unable to find certificate > ${values?.productNumber?.trim()} > ${values?.lotNumber?.trim()}`,
          eventInteractionType: 0,
        }
      )
    if (
      props.type === CertificateType.Coa ||
      props.type === CertificateType.Coo
    ) {
      setCertificateNotFoundModal(props.type)
    } else {
      setGlobalSnackbarState({
        open: true,
        message: intl.formatMessage(
          {
            id: 'NO_PRODUCT_DOC_ONLINE',
            defaultMessage:
              'In some cases, our product documentation may not be available online. Please {contactUs} to request a copy.',
          },
          {
            contactUs: (
              <Linq href={support.customerSupport()}>
                <FormattedMessage
                  id="CONTACT_US_LOWERCASE"
                  defaultMessage="contact us"
                />
              </Linq>
            ),
          }
        ),
        variant: SnackbarType.Error,
      })
    }
  }
}

interface QueryOptions {
  query: DocumentNode
  variables: QueryOptionsVariables
}

interface QueryOptionsVariables {
  productNumber: string | undefined
  lotNumber: string
  brand: string
  documentType: string
}

/**
 * Function to convert the full product number to the shortened version
 * @param productNumber string version of the product number for the certificate
 * @return the new product number with decimal format in string. (e.g 1008005000 -> 1.00800)
 */
const convertSkuToProductNumber = (productNumber: string): string =>
  `${productNumber[0]}.${productNumber.slice(1, 6)}`

/**
 * Function for formatting the product number for query variables
 * @param productNumber current items product number
 * @returns formatted product number
 */
const formatProductNumber = (productNumber: string): string => {
  // Trim whitespace
  const cleanProductNumber = productNumber.trim()

  /**
   * If the productNumber starts with a letter, or contains a dash,
   * underscore, forward slash, or dot . then leave it as is
   */
  if (
    /^[a-zA-Z]/.test(cleanProductNumber) ||
    /[-_/]/.test(cleanProductNumber) ||
    cleanProductNumber.includes('.')
  ) {
    return cleanProductNumber
  }

  // Otherwise convert SKU to product number
  return convertSkuToProductNumber(cleanProductNumber)
}

export const handleGetCertificate = async (
  client: ApolloClient<object>,
  props: CertificateFormProps,
  intl: IntlShape,
  userSession: UserSession,
  setGlobalSnackbarState: React.Dispatch<
    React.SetStateAction<GlobalSnackbarState>
  >,
  values: CertificateFormValues,
  setCertificateNotFoundModal: React.Dispatch<
    React.SetStateAction<CertificateType | null>
  >,
  setPDFLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmitting: (isSubmitting: boolean) => void
) => {
  const isCOA = props.type === CertificateType.Coa
  const documentType = AbbreviatedQmDocumentType[props.type]
  const queryOptionsProductNumber = formatProductNumber(
    values.productNumber || props.productNumber || ''
  )

  const queryOptions: QueryOptions = {
    query: isCOA ? CertificateOfAnalysisDocument : CertificateOfOriginDocument,
    variables: {
      productNumber: queryOptionsProductNumber,
      lotNumber: values.lotNumber.trim(),
      documentType,
      brand: props.brandKey || '',
    },
  }

  const dataAccessor = isCOA
    ? 'getCertificateOfAnalysis'
    : 'getCertificateOfOrigin'

  try {
    const { data } = await client.query({
      query: queryOptions.query,
      variables: {
        productNumber: queryOptions.variables.productNumber,
        lotNumber: queryOptions.variables.lotNumber,
        documentType: queryOptions.variables.documentType,
        count: 1,
        sendLotNumber: false,
      },
    })

    const certificate = data[dataAccessor]
    const { owner, unit, source } = certificate

    if (source === 'GDI' && values) {
      let certificatePropsProductNumber =
        values?.productNumber || props?.productNumber

      if (owner === 'MDA' && unit === 'CHEM' && values.sku) {
        certificatePropsProductNumber = values.sku
      }

      const certificateProps = Object.assign({}, props, {
        productNumber: certificatePropsProductNumber,
        text: { owner, unit },
      })

      handleCertificatePdf(
        client,
        certificateProps,
        values,
        intl,
        setGlobalSnackbarState,
        setPDFLoading,
        setCertificateNotFoundModal
      )
    } else if (source !== 'GDI' && props.isBlueProduct) {
      handleCertificatePdf(
        client,
        props,
        values,
        intl,
        setGlobalSnackbarState,
        setPDFLoading,
        setCertificateNotFoundModal
      )
    } else {
      showCertificate(
        client,
        dataAccessor,
        intl,
        setGlobalSnackbarState,
        isCOA,
        undefined,
        queryOptions,
        userSession,
        props,
        setCertificateNotFoundModal,
        values
      )
    }
  } catch (error) {
    sendErrorEvent(
      {
        description: `${props.type?.toLowerCase()} not found`,
        errorCategory: 'file download errors',
        productId: values?.sku || values?.productNumber,
        lotNumber: values?.lotNumber,
      },
      {
        eventCategory: 'downloads',
        eventAction: `${props.type?.toLowerCase()} search`,
        eventLabel: `Sorry, unable to find certificate > ${values?.productNumber?.trim()} > ${values?.lotNumber?.trim()}`,
        eventInteractionType: 0,
      }
    )
    setPDFLoading(false)
    if (
      (props.type === CertificateType.Coa ||
        props.type === CertificateType.Coo) &&
      setCertificateNotFoundModal
    ) {
      setCertificateNotFoundModal(props.type)
    } else {
      setGlobalSnackbarState({
        open: true,
        message: intl.formatMessage(
          {
            id: 'NO_PRODUCT_DOC_ONLINE',
            defaultMessage:
              'In some cases, our product documentation may not be available online. Please {contactUs} to request a copy.',
          },
          {
            contactUs: (
              <Linq href={support.customerSupport()}>
                <FormattedMessage
                  id="CONTACT_US_LOWERCASE"
                  defaultMessage="contact us"
                />
              </Linq>
            ),
          }
        ),
        variant: SnackbarType.Error,
      })
    }
  } finally {
    // isSubmitting needs to be updated or the submit button remains disabled
    setSubmitting(false)
  }
}

const handleSubmit =
  (
    client: ApolloClient<object>,
    props: CertificateFormProps,
    intl: IntlShape,
    userSession: UserSession,
    setGlobalSnackbarState: React.Dispatch<
      React.SetStateAction<GlobalSnackbarState>
    >,
    setCertificateNotFoundModal: React.Dispatch<
      React.SetStateAction<CertificateType | null>
    >,
    setPDFLoading: React.Dispatch<React.SetStateAction<boolean>>
  ): FormikConfig<CertificateFormValues>['onSubmit'] =>
  (values: CertificateFormValues, { setSubmitting }) => {
    handleGetCertificate(
      client,
      props,
      intl,
      userSession,
      setGlobalSnackbarState,
      values,
      setCertificateNotFoundModal,
      setPDFLoading,
      setSubmitting
    )
  }

interface ValidationErrors {
  [key: string]: string | undefined
}

const handleValidation = (
  values: {
    lotNumber: string
    productNumber: string
    sku: string
  },
  intl: IntlShape,
  props: CertificateFormProps
) => {
  const errors: ValidationErrors = {}
  if (!values.lotNumber.trim()) {
    errors.lotNumber = intl.formatMessage(
      messages.PLEASE_ENTER_LOT_BATCH_NUMBER
    )
  }
  if (!values.productNumber.trim()) {
    errors.productNumber = intl.formatMessage(
      messages.PLEASE_ENTER_PRODUCT_NUMBER
    )
  }
  if (props.showSkuInput && !values.sku.trim()) {
    errors.sku = intl.formatMessage(messages.MUST_ENTER_SKU)
  }
  return errors
}

const CertificateForm: React.FC<CertificateFormProps> = (props) => {
  const [pdfLoading, setPDFLoading] = useState(false)
  const [pdpProductNumber, setPdpProductNumber] = useState<string | null>(null)
  const intl = useIntl()
  const classes = useStyles()
  const theme = useTheme()
  const { userSession } = useUserSession()
  const { setGlobalSnackbarState } = useGlobalSnackbar()
  const router = useRouter()
  const { lotNo, productNo } = router.query

  const [certificateNotFoundModal, setCertificateNotFoundModal] =
    useState<CertificateType | null>(null)
  const productSearchType =
    props.type === CertificateType.Coa
      ? CertificateSearchType.CofAProduct
      : CertificateSearchType.CofOProduct
  const lotSearchType =
    props.type === CertificateType.Coa
      ? CertificateSearchType.CofALot
      : props.type === CertificateType.Coq
        ? CertificateSearchType.CoqLot
        : CertificateSearchType.CofOLot

  const { data } = useSampleCertificateOfAnalysisQuery({
    variables: {
      productNumber: props.productNumber || '',
      brand: props.brandKey || '',
    },
    skip: isServer() || props.dontShowSampleLink,
  })

  useEffect(() => {
    const storedPdpProductNumber = localStorage.getItem(
      'pdpProductNumber'
    ) as string

    if (storedPdpProductNumber) {
      setPdpProductNumber(storedPdpProductNumber)
      localStorage.removeItem('pdpProductNumber')
    }
  }, [])

  const getSkuDropdownOptions = () => {
    if (!props.options || !props.options.length) return []

    return props.options.map((option) => ({ label: option, value: option }))
  }

  const getCertificateType = (type: CertificateType): string =>
    CertificateName[type]
  const showSampleCoa =
    props.type === CertificateType.Coa &&
    !!props.productNumber &&
    data?.getSampleCertificate

  const fieldLabel = (key: string, error?: string) => (
    <Typography
      variant="body2"
      style={{
        color: error ? theme.palette.error.main : theme.palette.common.black,
      }}
    >
      <b>
        {props.allFieldsRequired && '* '}
        <FormattedMessage {...messages[key]} />
      </b>
    </Typography>
  )

  return (
    <ApolloConsumer>
      {(client) => (
        <>
          <div className={classes.titleContainer}>
            <Typography variant="h3" className={classes.title}>
              {props.title}
            </Typography>
          </div>
          {props.isExpandedLayout && (
            <Typography variant="body2" className={classes.vr4}>
              <FormattedMessage
                id="CERT_SUMMARY"
                defaultMessage="Search for {certName} by entering the products Lot/Batch Number. Lot and Batch Numbers can be found on a product’s label following the words ‘Lot’ or ‘Batch’."
                values={{
                  certName: props.title,
                }}
              />
            </Typography>
          )}
          {!props.isExpandedLayout && !props.instructions && (
            <>
              {!props.productInput && (
                <Typography variant="body2" className={classes.vr4}>
                  {intl.formatMessage(messages[`ENTER_${props.type}`])}
                </Typography>
              )}
              {props.showSkuInput && props.options && (
                <Typography variant="body2" className={classes.vr4}>
                  <FormattedMessage
                    {...messages.SELECT_PRODUCT_NUMBER_AND_ENTER_LOT_NUMBER}
                  />{' '}
                  {intl.formatMessage(
                    messages[`${getCertificateType(props.type)}`]
                  )}{' '}
                  ({intl.formatMessage(messages[`${props.type}`])})
                </Typography>
              )}
              {!props.showSkuInput && props.productInput && (
                <Typography variant="body2" className={classes.vr4}>
                  <FormattedMessage
                    {...messages.ENTER_PRODUCT_AND_LOT_NUMBER}
                  />
                </Typography>
              )}
            </>
          )}

          <div
            className={clsx(props.isExpandedLayout && classes.expandedLayout)}
          >
            <Formik
              enableReinitialize
              initialValues={{
                lotNumber: props.lotNumber || lotNo || '',
                productNumber:
                  pdpProductNumber || props.productNumber || productNo || '',
                sku: '',
              }}
              onSubmit={handleSubmit(
                client,
                props,
                intl,
                userSession,
                setGlobalSnackbarState,
                setCertificateNotFoundModal,
                setPDFLoading
              )}
              validateOnBlur={false}
              validateOnChange={false}
              validate={(values) => handleValidation(values, intl, props)}
            >
              {(formikBag: FormikProps<CertificateFormValues>) => (
                <Form
                  data-testid={`${props.type}-form`}
                  className={classes.formMargin}
                >
                  <div>
                    {
                      <div
                        className={
                          props.instructions ? classes.vr6 : classes.vr2
                        }
                      >
                        {props.instructions && (
                          <>
                            <Typography
                              className={clsx(
                                classes.vr4,
                                classes.instructions
                              )}
                            >
                              {props.instructions}
                            </Typography>
                            <Typography variant="body2" className={classes.vr4}>
                              <FormattedMessage
                                {...messages.INDICATES_REQUIRED_FIELD}
                              />
                            </Typography>
                          </>
                        )}
                        {props.showSkuInput && props.options ? (
                          <>
                            <Field
                              name="sku"
                              label={fieldLabel(
                                'PRODUCT_NUMBER',
                                formikBag.errors.sku
                              )}
                              component={LiquidSelectAdapter}
                              brand={props.brandKey}
                              inputProps={{ autoComplete: 'off' }}
                              options={getSkuDropdownOptions()}
                              displayEmpty
                              renderValue={(value: string): React.ReactNode =>
                                (value !== ''
                                  ? value
                                  : intl.formatMessage(
                                      messages.SELECT_A_PRODUCT_NUMBER
                                    )) as string
                              }
                            />
                          </>
                        ) : (
                          <>
                            {props.productInput && (
                              <>
                                <div className={classes.vr2}>
                                  <Field
                                    name="productNumber"
                                    label={fieldLabel(
                                      'PRODUCT_NUMBER',
                                      formikBag.errors.productNumber
                                    )}
                                    filled
                                    component={
                                      LiquidCertificateAutoCompleteAdapter
                                    }
                                    placeholder="e.g. T1503"
                                    brand={props.brandKey}
                                    inputProps={{ autoComplete: 'off' }}
                                    type={productSearchType}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    }
                    <div className={classes.vr6}>
                      <Field
                        name="lotNumber"
                        label={fieldLabel(
                          'LOT_BATCH_NUMBER',
                          formikBag.errors.lotNumber
                        )}
                        component={LiquidCertificateAutoCompleteAdapter}
                        placeholder="e.g. 023J5431"
                        filled
                        brand={props.brandKey}
                        productNumber={formikBag.values.productNumber.trim()}
                        type={lotSearchType}
                        inputProps={{
                          'data-testid': `${lotSearchType}-input`,
                          autoComplete: 'off',
                        }}
                        onChange={props.onChangeLotNumber}
                      />
                    </div>
                    <div className={classes.vr4}>
                      {!props.isExpandedLayout && !props.instructions && (
                        <LotNumberInstructionsDialog
                          type={props.type}
                          productNumber={props.productNumber}
                        />
                      )}
                    </div>
                    <Button
                      data-testid={`${lotSearchType}-submit`}
                      id={`${lotSearchType}-submit`}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.submitButton}
                      disabled={formikBag.isSubmitting || pdfLoading}
                      aria-label="Submit"
                    >
                      <FormattedMessage {...messages.SEARCH} />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>

            {showSampleCoa && props.isExpandedLayout && (
              <NeedSampleCOA>
                <ViewSampleCoa
                  linkType={SampleCoaLinkType.button}
                  props={props}
                  data={data}
                  client={client}
                />
              </NeedSampleCOA>
            )}
          </div>

          <CertficateNotFoundModal
            certificateType={props.type}
            open={!isNil(certificateNotFoundModal)}
            onClose={() => setCertificateNotFoundModal(null)}
          >
            {showSampleCoa && (
              <ViewSampleCoa
                linkType={SampleCoaLinkType.text}
                props={props}
                data={data}
                client={client}
              />
            )}
          </CertficateNotFoundModal>
          {pdfLoading && <CircleLoader />}
        </>
      )}
    </ApolloConsumer>
  )
}

export default CertificateForm
